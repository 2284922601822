
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElInput, FormInstance, FormRules, ElNotification } from 'element-plus'

import { apiErrorNotification } from '@/core/helpers'
import { authStore } from '@/store'
import { ISetPasswordForm } from '@/types'
import { usersService } from '@/services'
import { usePermissions } from '@/core/compositions'

export default defineComponent({
  name: 'SetPasswordView',

  components: { ElInput },

  setup() {
    const router = useRouter()

    const { defaultRedirectRoute } = usePermissions()

    const loading = ref(false)

    const formRef = ref<FormInstance>()
    const formValues = reactive<ISetPasswordForm>({
      password: '',
      confirmPassword: '',
    })

    const formValidationRules = reactive<FormRules>({
      password: [
        {
          required: true,
          message: 'Password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'Password length should be 10 to 64',
          trigger: 'change',
        },
      ],
      confirmPassword: [
        {
          required: true,
          message: 'Confirm password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'Password length should be 10 to 64',
          trigger: 'change',
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value !== formValues.password) {
              callback(new Error('Passwords not matches!'))
            } else {
              callback()
            }
          },
          message: 'Passwords not matches!',
          trigger: 'change',
        },
      ],
    })

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await usersService.setPassword(formValues)

        await authStore.getUser()

        ElNotification({
          message: 'Password successfully updated!',
          type: 'success',
        })

        router.push({ name: defaultRedirectRoute.value })
      } catch (error) {
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onLogoutClick = () => {
      authStore.logout()
    }

    return {
      formRef,
      formValues,
      formValidationRules,
      loading,
      onSubmit,
      onLogoutClick,
    }
  },
})
